<template>
    <div id="transaction-history" :class="{ 'pr-3' : $vuetify.breakpoint.width > 480}">

        <status class="mt-2" :class="{ 'mr-6': showAside }" @resetStatus="errorClear($options.name)"
            v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <status class="mt-2" :class="{ 'mr-6': showAside }" @resetStatus="successClear($options.name)"
            v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

        <hb-notification v-model="notification" v-if="notification" class="ma-0 pa-0 mt-2" :class="{ 'pr-6': showAside }"
            :type="notification_type">
            {{ notification_message }}
        </hb-notification>

        <div>
            <v-row class="ma-0 px-0 py-0">
                <v-col cols="12" class="px-0 d-flex align-center" :class="{'custom-flex-gap' : $vuetify.breakpoint.xs}">
                    <div style="width:125px;">
                        <space-selection-dropdown :setSelection="false" :mainViewOptions="mainViewOptions"
                            :contactLeases="contactLeases" @spaceSelectionChanged="filterDataOnSelectedSpace">
                        </space-selection-dropdown>

                    </div>
                    <div class="pl-2">
                        <v-menu v-model="showFilters" :close-on-content-click="false" offset-y
                            content-class="hb-elevation-large">
                            <template v-slot:activator="{ on }">
                                <div v-on="on" class="d-flex align-center pl-4 pr-2 select-filter"
                                    :class="{ 'download-center-filters-active': showFilters }">
                                    <span class="font-weight-regular hb-default-font-size">{{ setFilterText.length > 0 ?
                                        setFilterText : 'Filter'}}</span>
                                    <v-spacer></v-spacer>
                                    <hb-icon color="#637381">mdi-filter-variant</hb-icon>
                                </div>
                            </template>

                            <v-card width="300">
                                <v-card-text class="pa-4" style="overflow: auto;">
                                    <div class="hb-text-light hb-default-font-size font-weight-medium">Transaction Type
                                    </div>
                                    <v-checkbox id="charges-checkbox" name="charges_checkbox" v-model="chargesCheckbox"
                                        hide-details class="mx-0 pa-0 my-3">
                                        <template v-slot:label>
                                            <span class="hb-text-light text-capitalize">Charges</span>
                                        </template>
                                    </v-checkbox>
                                    <v-checkbox class="mx-0 pa-0 my-3" id="payments-checkbox" name="payments_checkbox"
                                        v-model="paymentsCheckbox" hide-details>
                                        <template v-slot:label>
                                            <span class="hb-text-light text-capitalize">Payments</span>
                                        </template>
                                    </v-checkbox>
                                    <div class="hb-text-light hb-default-font-size font-weight-medium mt-4">Date Range
                                    </div>
                                    <v-select :items="dateRangeOptions" background-color="white" hide-details
                                        single-line label="Select"
                                        :menu-props="{ bottom: true, offsetY: true, contentClass: 'hb-elevation-large' }"
                                        v-model="dateRange" id="dateRange" class="pt-0">
                                    </v-select>
                                    <span v-if="dateRange === 'Custom'">
                                        <hb-date-picker @click:clear="customStartDate = ''" label="Start Date"
                                            :clearable="true" :solo="false" data-vv-as="start_date"
                                            v-model="customStartDate"></hb-date-picker>
                                        <hb-date-picker v-if="customStartDate.length > 0"
                                            @click:clear="customEndDate = ''" label="End Date" :min="customStartDate"
                                            :clearable="true" :solo="false" data-vv-as="end_date"
                                            v-model="customEndDate"></hb-date-picker>
                                    </span>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions class="px-4 py-3"
                                    style="background: linear-gradient(180deg, #fafbfc 0%, #ebeff2 100%);">
                                    <hb-link color="tertiary" @click="resetFilters('bigReset')">Clear Filters</hb-link>
                                    <v-spacer></v-spacer>
                                    <hb-btn color="secondary" small
                                        @click="goFilter(dateRange, customStartDate, customEndDate, chargesCheckbox, paymentsCheckbox)"
                                        :disabled="(!chargesCheckbox && !paymentsCheckbox && dateRange.length === 0) || (dateRange === 'Custom' && (customStartDate.length === 0 || customEndDate.length === 0))">Done</hb-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                    </div>
                    <div class="pl-2">
                        <hb-link @click="setView">{{ !showDetailed ? 'Show Detailed View' : 'Hide Detailed View'}}</hb-link>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="pr-0 text-right">
                        <v-text-field outlined hide-details single-line dense v-model="search"
                            prepend-inner-icon="mdi-magnify" clearable clear-icon="mdi-close-circle"
                            class="hb-text-field-custom vuetify-input-fieldset-fix"
                            label="Search Transaction History"></v-text-field>
                    </div>
                    <div class="pr-0 text-right">

                    </div>
                </v-col>
            </v-row>
        </div>

        <v-card class="mb-4" :class="{ 'mr-0': showAside }" elevation="0">

            <v-data-table :headers="filteredHeaders" :items="newLedger" :item-class="rowClass"
                class="hb-data-table hb-data-table-cursor-on" @click:row="filter" disable-pagination hide-default-footer
                :search="search" :loading="newLedgerIsLoading" loading-text="Loading Transaction History..."
                :custom-filter="filterSearching" :custom-sort="customSort" group-by="category">
                <template v-slot:group.header="{ group, items, isOpen, toggle }">
                    <td class="header-td-height" v-if="group == 'future'"></td>
                    <td class="header-td-height" v-if="group == 'future'"></td>
                    <td class="px-0 header-td-height furture-charges" v-if="group == 'future'">
                        <span @click="toggle" id="futureClick">
                            <span v-if="isOpen">Hide</span><span v-else>Show</span> Future Charges ({{ items.length }})
                            <v-icon color="#00848e" class="mr-0 pr-0" v-if="isOpen">mdi-menu-up</v-icon>
                            <v-icon color="#00848e" class="mr-0 pr-0" v-else>mdi-menu-down</v-icon>
                        </span>
                    </td>
                    <td class="header-td-height" v-if="group == 'future'"></td>
                    <td class="header-td-height" v-if="group == 'future'"></td>
                    <td class="header-td-height" v-if="group == 'future'"></td>
                    <td class="header-td-height" v-if="group == 'future'"></td>
                </template>
                <template v-slot:item.unit_nbr="{ item }">
                    <div
                        :class="{ 'open-margin': showDetailed && (item.note || (item.invoices && item.invoices.length > 0) || (item.lines && item.lines.length > 0)) || openRows.indexOf(item.row_guid) >= 0 }">
                        <div class="hb-transaction-row">
                            <span v-if="item.unit_nbr" class="d-flex align-top">
                                <hb-icon color="#101318" small class="pr-1" v-if="item.unit_type === 'storage'"
                                    mdi-icon="mdi-facility-custom-3"></hb-icon>
                                <hb-icon color="#101318" small class="pr-1"
                                    v-else-if="item.unit_type === 'parking'">mdi-truck</hb-icon>
                                <hb-icon color="#101318" small class="pr-1"
                                    v-else-if="item.unit_type === 'company'">mdi-domain</hb-icon>
                                <hb-icon color="#101318" small class="pr-1"
                                    v-else-if="item.unit_type === 'residential'">mdi-home</hb-icon>
                                {{ item.unit_nbr }}
                            </span>
                            <span
                                v-else-if="(item.type === 'payment' || item.type === 'auction-payment') && item.invoices && item.invoices.length > 1">
                                <span v-if="checkIfSameUnits(item.invoices)" class="d-flex align-top">
                                    <hb-icon color="#101318" small class="pr-1"
                                        v-if="item.invoices[0].unit_type === 'storage'"
                                        mdi-icon="mdi-facility-custom-3"></hb-icon>
                                    <hb-icon color="#101318" small class="pr-1"
                                        v-else-if="item.invoices[0].unit_type === 'parking'">mdi-truck</hb-icon>
                                    <hb-icon color="#101318" small class="pr-1"
                                        v-else-if="item.invoices[0].unit_type === 'company'">mdi-domain</hb-icon>
                                    <hb-icon color="#101318" small class="pr-1"
                                        v-else-if="item.invoices[0].unit_type === 'residential'">mdi-home</hb-icon>
                                    {{ item.invoices[0].unit_nbr }}
                                </span>
                                <span v-else class="d-flex align-top">
                                    <hb-icon color="#101318" small class="pr-1"
                                        mdi-icon="mdi-facility-custom-3"></hb-icon>
                                    ({{ item.invoices.length }})
                                </span>
                            </span>
                            <span
                                v-else-if="(item.type === 'payment' || item.type === 'auction-payment') && item.invoices && item.invoices.length === 1"
                                class="d-flex align-top">
                                <hb-icon color="#101318" small class="pr-1"
                                    v-if="item.invoices[0].unit_type === 'storage'"
                                    mdi-icon="mdi-facility-custom-3"></hb-icon>
                                <hb-icon color="#101318" small class="pr-1"
                                    v-else-if="item.invoices[0].unit_type === 'parking'">mdi-truck</hb-icon>
                                <hb-icon color="#101318" small class="pr-1"
                                    v-else-if="item.invoices[0].unit_type === 'company'">mdi-domain</hb-icon>
                                <hb-icon color="#101318" small class="pr-1"
                                    v-else-if="item.invoices[0].unit_type === 'residential'">mdi-home</hb-icon>
                                {{ item.invoices[0].unit_nbr }}
                            </span>
                            <span v-else>

                            </span>
                        </div>
                        <span v-if="item.note">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0">
                            </div>
                        </span>
                        <span v-if="item.type === 'payment' || item.type === 'auction-payment'">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(invoice, i) in item.invoices">
                                <span v-if="checkIfSameUnits(item.invoices)">
                                    <!-- please leave these blank divs for spacing reasons -->
                                </span>
                                <span v-else class="d-flex align-center mt-0">
                                    <hb-icon color="#101318" small class="pr-1" v-if="invoice.unit_type === 'storage'"
                                        mdi-icon="mdi-facility-custom-3"></hb-icon>
                                    <hb-icon color="#101318" small class="pr-1"
                                        v-else-if="invoice.unit_type === 'parking'">mdi-truck</hb-icon>
                                    <hb-icon color="#101318" small class="pr-1"
                                        v-else-if="invoice.unit_type === 'company'">mdi-domain</hb-icon>
                                    <hb-icon color="#101318" small class="pr-1"
                                        v-else-if="invoiceunit_type === 'residential'">mdi-home</hb-icon>
                                    {{ invoice.unit_nbr }}
                                </span>
                            </div>
                        </span>
                        <span v-else-if="item.type === 'invoice' || item.type === 'write-off' || item.type === 'void'">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(line, i) in item.lines">
                                <!-- please leave these blank divs for spacing reasons -->
                            </div>
                        </span>
                    </div>
                </template>
                <template v-slot:item.date="{ item }">
                    <div
                        :class="{ 'open-margin': showDetailed && (item.note || (item.invoices && item.invoices.length > 0) || (item.lines && item.lines.length > 0)) || openRows.indexOf(item.row_guid) >= 0 }">
                        <div class="hb-transaction-row">{{ item.date | formatDateServices }}</div>
                        <span v-if="item.note">
                            <div class="hb-sub-transaction-row"
                            v-if="showDetailed || (openRows.indexOf(item.row_guid) >= 0) && propertyView != 'all-property'" >
                            </div>
                            <div class="hb-sub-transaction-row"
                                v-if="(showDetailed || openRows.indexOf(item.row_guid) >= 0 )&& propertyView == 'all-property'">
                                   <!-- please leave these blank divs for spacing reasons -->
                            </div>
                        </span>
                        <span v-if="item.type === 'payment' || item.type === 'auction-payment'">
                            
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(invoice, i) in item.invoices">
                                <!-- please leave these blank divs for spacing reasons -->
                            </div>
                        </span>
                        <span v-else-if="item.type === 'invoice' || item.type === 'write-off' || item.type === 'void'">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(line, i) in item.lines">
                                <!-- please leave these blank divs for spacing reasons -->
                            </div>
                        </span>
                        <span v-else-if="item.type === 'refund' ">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0 && propertyView == 'all-property'"
                               >
                                    <!-- please leave these blank divs for spacing reasons -->
                            </div>
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(line, i) in item.refunds">
                                <!-- please leave these blank divs for spacing reasons -->
                                
                            </div>
                        </span>
                    </div>
                </template>
                <template v-slot:item.description="{ item }">
                    <div
                        :class="{ 'open-margin': showDetailed && (item.note || (item.invoices && item.invoices.length > 0) || (item.lines && item.lines.length > 0)) || openRows.indexOf(item.row_guid) >= 0 }">
                        <div class="hb-transaction-row">
                            <span
                                v-if="item.type === 'refund' || item.type === 'reversal' || item.type === 'payment-void' || item.type === 'auto-payment-failed'">
                                <span class="font-weight-medium"><HbIcon
                                                v-if = "propertyView == 'all-property'"
                                                small
                                                mdi-code="mdi-cash-refund"
                                                class="hb-communication-audit-trail-icon-adjustment"
                                            />{{ item.description.prefix }}</span> {{
                                item.description.text }}
                            </span>
                            <span v-else>
                                <hb-link color="secondary" class="text-capitalize" @click="goDescription(item)"
                                    v-if="item.description">
                                    <HbIcon
                                                v-if = "propertyView == 'all-property' &&  item.type === 'payment'"
                                                small
                                                mdi-code="mdi-currency-usd	"
                                                class="hb-communication-audit-trail-icon-adjustment"
                                            />
                                    <span class="font-weight-medium" v-if="item.description.prefix">{{
                                        item.description.prefix }}</span> <span v-if="item.description.text">{{
                                        item.description.text }}</span>
                                </hb-link>
                            </span>
                        </div>
                        <span v-if="item.note">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || (openRows.indexOf(item.row_guid) >= 0) && propertyView != 'all-property'" >
                                <span class="font-weight-medium font" style="font-style: italic;">{{ item.note }}</span>
                            </div>
                            <div class="hb-sub-transaction-row"
                                v-if="(showDetailed || openRows.indexOf(item.row_guid) >= 0 )&& propertyView == 'all-property'">
                                <span>
                                    <span> <HbIcon
                                                small
                                                mdi-code="mdi-account-clock-outline"
                                                class="hb-communication-audit-trail-icon-adjustment"
                                            />
                                              Paid: {{item.date | formatDateServices}} @ {{item.date | formatTime12Hour}} </span>
                                </span>
                            </div>
                        </span>
                        <span v-if="item.type === 'payment' || item.type === 'auction-payment'">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(invoice, i) in item.invoices">
                                <hb-link color="secondary" class="text-capitalize"
                                    @click="goInvoice(invoice.invoice_id)">
                                    <span class="font-weight-medium">{{ invoice.description.prefix }}</span> {{
                                    invoice.description.text }}
                                </hb-link>
                            </div>
                        </span>
                        <span v-else-if="item.type === 'invoice' || item.type === 'write-off' || item.type === 'void'">
                            
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(line, i) in item.lines">
                                {{ line.description }}<span
                                    v-if="line.product_type === 'rent' || line.product_type === 'insurance'"> - ({{
                                        line.start_date | formatDateServices }} - {{ line.end_date | formatDateServices
                                    }})</span>
                            </div>
                        </span>
                        <span v-else-if="item.type === 'refund' ">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0 && propertyView == 'all-property'"
                               >
                               <span>
                                    <span> <HbIcon
                                                small
                                                mdi-code="mdi-account-clock-outline"
                                                class="hb-communication-audit-trail-icon-adjustment"
                                            />
                                              Refunded: {{item.date | formatDateServices}} @ {{item.date | formatTime12Hour}}</span>
                                </span>
                            </div>
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(line, i) in item.refunds">
                                {{ line.payment_number }}
                            </div>
                        </span>
                    </div>
                </template>
                <template v-slot:item.period="{ item }">
                    <div
                        :class="{ 'open-margin': showDetailed && (item.note || (item.invoices && item.invoices.length > 0) || (item.lines && item.lines.length > 0)) || openRows.indexOf(item.row_guid) >= 0 }">
                        <div class="hb-transaction-row" style="max-height:18px;">
                            <span v-if="item.type === 'invoice' && propertyView == 'all-property'">
                               <span>{{item.period_start| formatDateServices}} - {{ item.period_end | formatDateServices }}</span> 
                            </span>
                            <span v-else-if="item.type === 'payment' && propertyView == 'all-property'"">
                                <span> 

                                </span>
                            </span>
                        </div>
                        <span v-if="item.note">
                            <div class="hb-sub-transaction-row"
                            v-if="showDetailed || (openRows.indexOf(item.row_guid) >= 0) && propertyView != 'all-property'">
                            </div>
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0 && propertyView == 'all-property'">
                                <span>
                                </span>
                            </div>
                        </span>
                        <span v-if="item.type === 'payment' || item.type === 'auction-payment'">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0 && propertyView == 'all-property'"
                                v-for="(invoice, i) in item.invoices">
                                <span>
                                    <span>{{ invoice.period_end | formatDateServices }} - {{
                                    invoice.period_end | formatDateServices }}</span>
                                </span>
                            </div>
                            <div class="hb-sub-transaction-row"
                                v-else-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(invoice, i) in item.invoices">
                                
                            </div>
                        </span>
                        <span v-else-if="item.type === 'invoice' || item.type === 'write-off' || item.type === 'void'">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(line, i) in item.lines">
                                {{line.allocation_date | formatDateServices }}
                                <!-- please leave these blank divs for spacing reasons -->
                            </div>
                            <div class="hb-sub-transaction-row align-right justify-end font-weight-medium"
                            v-if="(showDetailed || openRows.indexOf(item.row_guid) >= 0) && item.unsettled_amount > 0">
                               Balance
                            </div>
                        </span>
                        <span v-else-if="item.type === 'refund' ">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0 && propertyView == 'all-property'"
                               >
                                    <!-- please leave these blank divs for spacing reasons -->
                            </div>
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(line, i) in item.refunds">
                                {{line.refund_date | formatDateServices}}
                                <!-- please leave these blank divs for spacing reasons -->
                                
                            </div>
                        </span>
                    </div>
                </template>
                <template v-slot:item.status="{ item }">
                    <div
                        :class="{ 'open-margin': showDetailed && (item.note || (item.invoices && item.invoices.length > 0) || (item.lines && item.lines.length > 0)) || openRows.indexOf(item.row_guid) >= 0 }">
                        <div class="hb-transaction-row" style="max-height:18px;">
                            <span v-if="item.status && (item.type === 'invoice' || item.type === 'void')">
                                <hb-status-general :status="item.status"></hb-status-general>
                            </span>
                            <span v-else-if = "item.status && item.type === 'payment' && propertyView == 'all-property'">
                                <hb-status-general :status="item.status"></hb-status-general>

                            </span>
                        </div>
                        <span v-if="item.note">
                            <div class="hb-sub-transaction-row"
                            v-if="showDetailed || (openRows.indexOf(item.row_guid) >= 0) && propertyView != 'all-property'" >
                            </div>
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0 && propertyView == 'all-property'">
                                <span>
                                    <hb-btn v-if="item.status==='Open'"  @click="goRecordRefund(item)" color="secondary" x-small>Refund</hb-btn>
                                </span>
                            </div>
                        </span>
                        <span v-if="item.type === 'payment' || item.type === 'auction-payment'">
                           
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(invoice, i) in item.invoices">
                                <!-- please leave these blank divs for spacing reasons -->
                            </div>
                        </span>
                        <span v-else-if="item.type === 'invoice' || item.type === 'write-off' || item.type === 'void'">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(line, i) in item.lines">
                                <!-- please leave these blank divs for spacing reasons -->
                            </div>
                        </span>
                        <span v-else-if="item.type === 'refund' ">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(line, i) in item.refunds">
                                <!-- please leave these blank divs for spacing reasons -->
                                
                            </div>
                        </span>
                    </div>
                </template>
                <template v-slot:item.charge="{ item }">
                    <div
                        :class="{ 'open-margin': showDetailed && (item.note || (item.invoices && item.invoices.length > 0) || (item.lines && item.lines.length > 0)) || openRows.indexOf(item.row_guid) >= 0 }">
                        <div class="hb-transaction-row">
                            <span v-if="item.type === 'invoice' || item.type === 'void'">
                                {{ item.amount | formatMoney }}
                            </span>
                            <span v-else-if="item.type === 'write-off'" class="hb-text-destroy-status">
                                {{ item.amount | formatMoney }}
                            </span>
                            <span v-else>

                            </span>
                        </div>
                        <span v-if="item.note">
                            <div class="hb-sub-transaction-row"
                            v-if="showDetailed || (openRows.indexOf(item.row_guid) >= 0) && propertyView != 'all-property'" >
                            </div>
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(invoice, i) in item.invoices">
                                <!-- please leave these blank divs for spacing reasons -->
                            </div>
                        </span>
                        <span v-if="item.type === 'payment' || item.type === 'auction-payment'">
                            
                        </span>
                        <span v-else-if="item.type === 'invoice' || item.type === 'write-off' || item.type === 'void'">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(line, i) in item.lines">
                                {{ line.amount | formatMoney }}
                            </div>
                            
                        </span>
                    </div>
                </template>
                <template v-slot:item.payment="{ item }">
                    <div
                        :class="{ 'open-margin': showDetailed && (item.note || (item.invoices && item.invoices.length > 0) || (item.lines && item.lines.length > 0)) || openRows.indexOf(item.row_guid) >= 0 }">
                        <div class="hb-transaction-row">
                            <span v-if="item.type === 'payment' || item.type === 'auction-payment'">
                                {{ -(item.amount) | formatMoney }}
                            </span>
                            <span
                                v-else-if="item.type === 'refund' || item.type === 'reversal' || item.type === 'payment-void' || item.type === 'auto-payment-failed'"
                                class="hb-text-destroy-status">
                                ({{ item.amount | formatMoney }})
                            </span>
                            <span
                                v-else-if="item.type === 'invoice' && propertyView == 'all-property'"
                                class="hb-text-destroy-status">
                                ({{ item.payment | formatMoney }})
                            </span>
                            <span v-else>

                            </span>
                        </div>
                        <span v-if="item.note">
                            <div class="hb-sub-transaction-row"
                            v-if="showDetailed || (openRows.indexOf(item.row_guid) >= 0) && propertyView != 'all-property'" >
                            </div>
                            <div class="hb-sub-transaction-row"
                                v-if="(showDetailed || openRows.indexOf(item.row_guid) >= 0 )&& propertyView == 'all-property'">
                                   <!-- please leave these blank divs for spacing reasons -->
                            </div>
                        </span>
                        <span v-if="item.type === 'payment' || item.type === 'auction-payment'">
                           
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(invoice, i) in item.invoices">
                                {{ invoice.amount | formatMoney }}
                            </div>
                        </span>
                        <span v-else-if="item.type === 'invoice' || item.type === 'write-off' || item.type === 'void'">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(line, i) in item.lines">
                                {{line.payment | formatMoney }}
                                <!-- please leave these blank divs for spacing reasons -->
                            </div>
                            <div class="hb-sub-transaction-row font-weight-medium"
                                v-if="(showDetailed || openRows.indexOf(item.row_guid) >= 0) && item.unsettled_amount > 0">
                                {{ item.unsettled_amount | formatMoney}}
                            </div>
                        </span>
                        <span v-else-if="item.type === 'refund' && propertyView == 'all-property'">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0 && propertyView == 'all-property'"
                               >
                                    <!-- please leave these blank divs for spacing reasons -->
                            </div>
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(line, i) in item.refunds">
                                {{ line.amount }}
                                <!-- please leave these blank divs for spacing reasons -->
                                
                            </div>
                        </span>
                    </div>
                </template>
                <template v-slot:item.running_balance="{ item }">
                    <div
                        :class="{ 'open-margin': showDetailed && (item.note || (item.invoices && item.invoices.length > 0) || (item.lines && item.lines.length > 0)) || openRows.indexOf(item.row_guid) >= 0 }">
                        <div class="hb-transaction-row">{{ item.running_balance | formatMoney }}</div>
                        <span v-if="item.note">
                            <div class="hb-sub-transaction-row"
                            v-if="showDetailed || (openRows.indexOf(item.row_guid) >= 0) && propertyView != 'all-property'" >
                            </div>
                            <div class="hb-sub-transaction-row"
                                v-if="(showDetailed || openRows.indexOf(item.row_guid) >= 0 )&& propertyView == 'all-property'">
                                   <!-- please leave these blank divs for spacing reasons -->
                            </div>
                        </span>
                        <span v-if="item.type === 'payment' || item.type === 'auction-payment'">
                           
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(invoice, i) in item.invoices">
                                {{invoice.invoice_payment | formatMoney}}
                                <!-- please leave these blank divs for spacing reasons -->
                            </div>
                        </span>
                        <span v-else-if="item.type === 'invoice' || item.type === 'write-off' || item.type === 'void'">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(line, i) in item.lines">
                                {{line.invoice_payment | formatMoney}}
                                <!-- please leave these blank divs for spacing reasons -->
                            </div>
                        </span>
                        <span v-else-if="item.type === 'refund' ">
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0 && propertyView == 'all-property'"
                               >
                                    <!-- please leave these blank divs for spacing reasons -->
                            </div>
                            <div class="hb-sub-transaction-row"
                                v-if="showDetailed || openRows.indexOf(item.row_guid) >= 0"
                                v-for="(line, i) in item.refunds">
                                <!-- please leave these blank divs for spacing reasons -->
                                
                            </div>
                        </span>
                    </div>
                </template>
            </v-data-table>
        </v-card>

        <!-- <edit-payment v-model="showView" v-if="showView" :payment_id="payment_id" @refetch="transactionUpdated"
            @close="showView = false" :modal="true">
        </edit-payment> -->

        <record-refund
            v-model="showRefundView"
            v-if="showRefundView"
            :payment_id="payment_id"
            :na_id="contact.national_account_id"
            @refetch-payment="transactionUpdated"
            @close="showRefundView = false"
            :modal="true">
        </record-refund>

        <display-invoice v-model="showInvoice" v-if="showInvoice" :propertyView="propertyView" :invoice_id="invoice_id"  :account_id = "contact.national_account_id"
            :contact_id="contact.id || $route.params.contact_id" @saved="fetchLedger(mainView)"
            @refreshInvoice="setInvoice" :fromVDialog="true" @close="showInvoice = false" :modal="true" :national_account="true">
        </display-invoice>
        <display-na-invoice v-model="showNaInvoice" v-if="showNaInvoice" :invoice_id="invoice_id" :account_id = "contact.national_account_id"
            :contact="contact || $route.params.contact_id" @saved="fetchLedger(mainView)"
            @refreshInvoice="setInvoice" :fromVDialog="true" @close="showNaInvoice = false" :modal="true">
        </display-na-invoice>

    </div>

</template>

<script type="text/babel">
import Modal from '../../assets/Modal.vue';
import Status from '../../includes/Messages.vue';
// import Loader from '../assets/CircleSpinner.vue';
import api from '../../../assets/api.js';
import EditPayment from '../../payments/EditPayment.vue';
import RecordRefund from '../../payments/RecordRefund.vue';
import ViewPayment from '../../payments/ViewPayment.vue';
import HbDatePicker from '../../assets/HummingbirdDatepicker.vue';
import DisplayInvoice from '../../invoices/DisplayInvoice.vue';
import moment from 'moment';
import {EventBus} from '../../../EventBus.js'
import SpaceSelectionDropdown from '../../settings/spaceSelectionDropdown/spaceSelectionDropdown.vue';
import DisplayNaInvoice from '../../invoices/DisplayNaInvoice.vue';

export default {
    name: 'ContactTransactionHistory',
    data() {
        return {
            showView: false,
            showRefundView: false,
            showInvoice: false,
            invoice_id: null,
            showNaInvoice: false,
            payment_id: null,
            ledger: [],
            headers: [
                { text: 'Space #', value: 'unit_nbr', groupable: false },
                { text: 'Date', value: 'date', width: '112px', groupable: false },
                { text: 'Description', value: 'description', width: '330px', groupable: false },
                { text: 'Status', value: 'status', width: '110px', groupable: false },
                { text: 'Charge', value: 'charge', groupable: false },
                { text: 'Payment', value: 'payment', groupable: false },
                { text: 'Balance', value: 'running_balance', groupable: false },
                { value: 'category', sortable: false, groupable: true }
            ],
            Naheaders: [
                { text: 'Date', value: 'date', width: '112px', groupable: false },
                { text: 'Description', value: 'description', width: '330px', groupable: false },
                { text: 'Service Period', value: 'period', groupable: false },
                { text: 'Amount', value: 'payment', groupable: false },
                { text: 'Balance', value: 'running_balance', groupable: false },
                { text: 'Status', value: 'status', width: '110px', groupable: false },
                { value: 'category', sortable: false, groupable: true }
            ],
            mainView: {
                id: null,
                Unit: {
                    number: 'Tenant',
                    type: 'tenant',
                }
            },
            notification: false,
            notification_type: '',
            notification_message: '',
            showFilters: false,
            dateRangeOptions: ['All Time', 'Last 30 Days', 'Custom'],
            dateRange: 'All Time',
            customStartDate: '',
            customEndDate: '',
            setFilterText: '',
            showDetailed: false,
            newLedger: [],
            openRows: [],
            selectedFilters: [],
            chargesCheckbox: false,
            paymentsCheckbox: false,
            search: '',
            newLedgerIsLoading: false,
            autoClickedFuture: false
        }
    },
    components: {
        Modal,
        Status,
        HbDatePicker,
        DisplayInvoice,
        DisplayNaInvoice,
        EditPayment,
        RecordRefund,
        ViewPayment,
        SpaceSelectionDropdown

    },
    async created() {
        if (this.propertyView) {
            await this.fetchLedger();
            this.hideFutureCharges();
        }

        EventBus.$on('lease_payment_saved', this.transactionUpdated);
        EventBus.$on('invoice_saved', this.transactionUpdated);
        EventBus.$on('show_lease_payment', this.showPayment);
        EventBus.$on('payment_saved', this.transactionUpdated);
        EventBus.$on('payment_updated', this.transactionUpdated);
        EventBus.$on('show_invoice', this.showInvoiceView);
        // this.$on('spaceSelectionChanged',this.filterDataOnSelectedSpace)
    },
    destroyed() {
        EventBus.$off('lease_payment_saved', this.transactionUpdated);
        EventBus.$off('invoice_saved', this.transactionUpdated);
        EventBus.$off('show_lease_payment', this.showPayment);
        EventBus.$off('payment_saved', this.transactionUpdated);
        EventBus.$off('payment_updated', this.transactionUpdated);
        EventBus.$off('show_invoice', this.showInvoiceView);
    },
    filters: {

    },
    computed: {
        mainViewOptions() {
            var options = [];
            options.push({
                id: null,
                Unit: {
                    number: 'Tenant',
                    type: 'tenant',
                }
            });
            this.contactLeases.forEach(lease => {
                options.push({
                    id: lease.id,
                    end_date: lease.end_date,
                    Unit: {
                        number: lease.Unit.number,
                        type: lease.Unit.type,
                    }
                });
            })
            return options;
        },
        filteredHeaders() {
            if (this.contact.national_account_id && this.propertyView == 'all-property') {
                return this.Naheaders;
            }
            return this.headers;
        }
    },
    methods: {
        async filterDataOnSelectedSpace(mainView) {
            console.log('invocation success filer', this.propertyView);
            // this.mainView = mainView
            this.newLedger = [];
            if (true) {
                await this.resetFilters();
                await this.fetchLedger(mainView);
                this.hideFutureCharges();
            }
        },
        filterSearching(value, search, item) {
            return value != null &&
                search != null &&
                (
                    value.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1
                    || (value.prefix && value.prefix.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1)
                    || (value.text && value.text.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1)
                    || (item.date && (moment(item.date).format("MMM DD, YYYY")).toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1)
                    || (item.amount && (Math.round(item.amount * 1e2) / 1e2).toFixed(2).toString().indexOf(search.toString()) !== -1)
                    || (item.running_balance && (Math.round(item.running_balance * 1e2) / 1e2).toFixed(2).toString().indexOf(search.toString()) !== -1)
                    || (item.invoices && item.invoices[0] && item.invoices[0].unit_nbr && item.invoices[0].unit_nbr.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1)
                )
        },
        customSort(items, index, isDesc) {
            let value_index = index[1]
            let is_desc_index = isDesc[1]
            items.sort((a, b) => {
                if (value_index === 'unit_nbr') {
                    var fa = '';
                    if (a.unit_nbr) {
                        fa = a.unit_nbr.toLowerCase();
                    } else if (!a.unit_nbr && a.invoices && a.invoices[0] && a.invoices[0].unit_nbr) {
                        fa = a.invoices[0].unit_nbr.toLowerCase();
                    } else {
                        ''
                    }

                    var fb = '';
                    if (b.unit_nbr) {
                        fb = b.unit_nbr.toLowerCase();
                    } else if (!b.unit_nbr && b.invoices && b.invoices[0] && b.invoices[0].unit_nbr) {
                        fb = b.invoices[0].unit_nbr.toLowerCase();
                    } else {
                        ''
                    }
                    if (!is_desc_index) {

                        if (fa > fb) {
                            return -1;
                        }
                        if (fa < fb) {
                            return 1;
                        }
                        return 0;
                    } else {

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }
                }
                if (value_index === 'date') {
                    if (!is_desc_index) {

                        if (a.date > b.date) {
                            return -1;
                        }
                        if (a.date < b.date) {
                            return 1;
                        }
                        return 0;
                    } else {

                        if (a.date < b.date) {
                            return -1;
                        }
                        if (a.date > b.date) {
                            return 1;
                        }
                        return 0;
                    }
                }
                if (value_index === 'description') {
                    var fa = a.description.prefix.toLowerCase() + a.description.text.toLowerCase(),
                        fb = b.description.prefix.toLowerCase() + b.description.text.toLowerCase();

                    if (!is_desc_index) {

                        if (fa > fb) {
                            return -1;
                        }
                        if (fa < fb) {
                            return 1;
                        }
                        return 0;
                    } else {

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }
                }
                if (value_index === 'status') {
                    var fa = '';
                    if ((a.type === 'invoice' || a.type === 'void') && a.status) {
                        fa = a.status.toLowerCase();
                    } else {
                        ''
                    }

                    var fb = '';
                    if ((b.type === 'invoice' || b.type === 'void') && b.status) {
                        fb = b.status.toLowerCase();
                    } else {
                        ''
                    }
                    if (!is_desc_index) {

                        if (fa > fb) {
                            return -1;
                        }
                        if (fa < fb) {
                            return 1;
                        }
                        return 0;
                    } else {

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }
                }
                if (value_index === 'charge') {
                    var fa = '';
                    if ((a.type === 'invoice' || a.type === 'void' || a.type === 'write-off') && a.amount) {
                        fa = a.amount;
                    } else {
                        fa = null;
                    }

                    var fb = '';
                    if ((b.type === 'invoice' || b.type === 'void' || b.type === 'write-off') && b.amount) {
                        fb = b.amount;
                    } else {
                        fb = null;
                    }
                    if (!is_desc_index) {

                        if (fa > fb) {
                            return -1;
                        }
                        if (fa < fb) {
                            return 1;
                        }
                        return 0;
                    } else {

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }
                }
                if (value_index === 'payment') {
                    var fa = '';
                    if ((a.type === 'payment' || a.type === 'auction-payment') && a.amount) {
                        fa = -a.amount;
                    }
                    else if (a.type === 'refund' && a.amount) {
                        fa = -a.amount;
                    } else if ((a.type === 'reversal' || a.type === 'payment-void' || a.type === 'auto-payment-failed') && a.amount) {
                        fa = a.amount;
                    } else {
                        fa = null;
                    }

                    var fb = '';
                    if ((b.type === 'payment' || b.type === 'auction-payment') && b.amount) {
                        fb = -b.amount;
                    }
                    else if (b.type === 'refund' && b.amount) {
                        fb = -b.amount;
                    } else if ((b.type === 'reversal' || b.type === 'payment-void' || b.type === 'auto-payment-failed') && b.amount) {
                        fb = b.amount;
                    } else {
                        fb = null;
                    }
                    if (!is_desc_index) {

                        if (fa > fb) {
                            return -1;
                        }
                        if (fa < fb) {
                            return 1;
                        }
                        return 0;
                    } else {

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }
                }
                if (value_index === 'running_balance') {
                    if (!is_desc_index) {

                        if (a.running_balance > b.running_balance) {
                            return -1;
                        }
                        if (a.running_balance < b.running_balance) {
                            return 1;
                        }
                        return 0;
                    } else {

                        if (a.running_balance < b.running_balance) {
                            return -1;
                        }
                        if (a.running_balance > b.running_balance) {
                            return 1;
                        }
                        return 0;
                    }
                }
            });

            return items;
        },
        setInvoice(invoice_id) {
            this.invoice_id = invoice_id;
            this.transactionUpdated();
        },
        /*
        async fetchData(lease){
            api.get(this, api.LEASES + lease.id + '/ledger').then(r => {
                this.ledger = r.ledger;
            });

        },
        */
        goDescription(item) {
            if(item.type === 'invoice' && this.propertyView ==="all-property"){
                 this.goNaInvoice(item.id);
            }
            else if (item.type === 'payment' || item.type === 'auction-payment') {
                this.showPayment(item.id);
            } else if (item.type === 'invoice' || item.type === 'void') {
                this.goInvoice(item.id);
            } else if (item.type === 'write-off') {
                this.goInvoice(item.invoice_id);
            }
        },
        filter(item) {
            if (item.note || (item.invoices && item.invoices.length > 0) || (item.lines && item.lines.length > 0)) {
                if (this.openRows.indexOf(item.row_guid) >= 0) {
                    this.openRows.splice(this.openRows.indexOf(item.row_guid), 1);
                } else {
                    this.openRows.push(item.row_guid);
                }
            }
        },
        showPayment(id) {
            this.payment_id = id;
            this.showInvoice = false;
            this.showView = true;
        },
        goInvoice(id) {
            this.invoice_id = id;
            this.showInvoice = true;
        },
        goNaInvoice(id) {
            this.invoice_id = id;
            this.showInvoice = false;
            this.showNaInvoice = true;
        },
        goRecordRefund(payment) {
            this.payment_id = payment.id;
            this.showRefundView = true;
        },
        async resetFilters(bigReset) {
            this.setFilterText = '';
            this.chargesCheckbox = false;
            this.paymentsCheckbox = false;
            this.dateRange = 'All Time';
            this.customStartDate = '';
            this.customEndDate = '';
            this.showFilters = false;
            if (bigReset === 'bigReset') {
                await this.fetchLedger(this.mainView);
                this.hideFutureCharges();
            }
        },

        async goFilter(dateRange, customStartDate, customEndDate, chargesCheckbox, paymentsCheckbox) {
            if ((chargesCheckbox || paymentsCheckbox) && dateRange !== 'All Time') {
                this.setFilterText = 'Custom';
            } else if (chargesCheckbox && paymentsCheckbox) {
                this.setFilterText = 'All'
            } else if (chargesCheckbox && !paymentsCheckbox && dateRange === 'All Time') {
                this.setFilterText = 'Charges';
            } else if (!chargesCheckbox && paymentsCheckbox && dateRange === 'All Time') {
                this.setFilterText = 'Payments';
            } else if (!chargesCheckbox || !paymentsCheckbox && dateRange.length > 0 && dateRange !== 'All Time') {
                this.setFilterText = dateRange;
            }
            if (dateRange !== 'Custom') {
                this.customStartDate = '';
                this.customEndDate = '';
            }
            this.showFilters = false,
                await this.fetchLedger(this.mainView, dateRange, customStartDate, customEndDate, chargesCheckbox, paymentsCheckbox);
            this.hideFutureCharges();
        },

        async fetchLedger(lease, dateRange, customStartDate, customEndDate, chargesCheckbox, paymentsCheckbox) {
            var selectedContact = '';
            if (this.contact && this.contact.id) {
                selectedContact = this.contact.id;
            } else {
                selectedContact = this.$route.params.contact_id;
            }
            this.newLedger = [];
            this.newLedgerIsLoading = true;
            var ledger = [];
            if(this.propertyView ==="all-property"){
                if (dateRange === 'Last 30 Days') {
                    let r = await api.get(this, api.NATIONAL_ACCOUNT + this.contact.national_account_id + '/transactions', { property_id: this.propertyView, from_date: moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DD'), to_date: moment().startOf('day').format('YYYY-MM-DD') });
                    ledger = r.transactions.transactions;
                } else if (dateRange === 'Custom') {
                    let r = await api.get(this, api.NATIONAL_ACCOUNT + this.contact.national_account_id + '/transactions', { property_id: this.propertyView, from_date: customStartDate, to_date: customEndDate });
                    ledger = r.transactions.transactions;
                } else {
                    let r = await api.get(this, api.NATIONAL_ACCOUNT + this.contact.national_account_id + '/transactions');
                    ledger = r.transactions.transactions;
                }
            }
            else if ((!lease || lease.id === null || !lease.id) && this.propertyView) {
                if (dateRange === 'Last 30 Days') {
                    let r = await api.get(this, api.CONTACTS + selectedContact + '/transactions', { property_id: this.propertyView, from_date: moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DD'), to_date: moment().startOf('day').format('YYYY-MM-DD') });
                    ledger = r.transactions.tenant_ledger;
                } else if (dateRange === 'Custom') {
                    let r = await api.get(this, api.CONTACTS + selectedContact + '/transactions', { property_id: this.propertyView, from_date: customStartDate, to_date: customEndDate });
                    ledger = r.transactions.tenant_ledger;
                } else {
                    let r = await api.get(this, api.CONTACTS + selectedContact + '/transactions', { property_id: this.propertyView });
                    ledger = r.transactions.tenant_ledger;
                }
            } else if (this.propertyView) {
                if (dateRange === 'Last 30 Days') {
                    let r = await api.get(this, api.CONTACTS + selectedContact + '/transactions', { lease_id: lease.id, property_id: this.propertyView, from_date: moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DD'), to_date: moment().startOf('day').format('YYYY-MM-DD') });
                    ledger = r.transactions.tenant_ledger;
                } else if (dateRange === 'Custom') {
                    let r = await api.get(this, api.CONTACTS + selectedContact + '/transactions', { lease_id: lease.id, property_id: this.propertyView, from_date: customStartDate, to_date: customEndDate });
                    ledger = r.transactions.tenant_ledger;
                } else {
                    let r = await api.get(this, api.CONTACTS + selectedContact + '/transactions', { lease_id: lease.id, property_id: this.propertyView });
                    ledger = r.transactions.tenant_ledger;
                }
            }
            if (chargesCheckbox && !paymentsCheckbox) {
                this.newLedger = ledger.filter(l =>
                    l.type === 'invoice' || l.type === 'write-off' || l.type === 'void'
                )
            } else if (paymentsCheckbox && !chargesCheckbox) {
                this.newLedger = ledger.filter(l =>
                    l.type === 'payment' || l.type === 'auction-payment' || l.type === 'refund' || l.type === 'reversal' || l.type === 'payment-void' || l.type === 'auto-payment-failed'
                )
            } else {
                this.newLedger = ledger;
            }
            this.newLedgerIsLoading = false;
        },
        hideFutureCharges() {
            if (document.getElementById("futureClick") && !this.newLedgerIsLoading && !this.autoClickedFuture) {
                document.getElementById("futureClick").click();
                this.autoClickedFuture = true;
            }
        },
        rowClass(item) {
            if (item.type === 'invoice') {
                if (item.category == 'future') return "row-future-invoice";
                return "row-invoice";
            } else if (item.type === 'payment' || item.type === 'auction-payment') {
                return "row-payment";
            } else if (item.type === 'refund' || item.type === 'reversal' || item.type === 'payment-void' || item.type === 'auto-payment-failed') {
                return "row-refund";
            } else if (item.type === 'write-off' || item.type === 'void') {
                if (item.category == 'future') return "row-future-void";
                return "row-void";
            }
        },
        setView() {
            this.openRows = [];
            this.showDetailed = !this.showDetailed;
        },
        checkIfSameUnits(invoices) {
            var sameUnitChecker = '';
            if (this.mainView.Unit.type === 'tenant') {
                sameUnitChecker = invoices.every(i => i.unit_nbr.toLowerCase() === invoices[0].unit_nbr.toLowerCase());
            } else {
                sameUnitChecker = invoices.every(i => i.unit_nbr.toLowerCase() === this.mainView.Unit.number.toLowerCase());
            }
            return sameUnitChecker;
        },
        transactionUpdated() {
          console.log('dfasdfsdafsdaf')
            this.newLedgerIsLoading = true;
            this.newLedger = [];
            setTimeout(() => {
                this.fetchLedger(this.mainView);
                this.hideFutureCharges();
            }, 6000);
        },
        showInvoiceView(invoice_id) {
            this.invoice_id = invoice_id;
            this.showView = false;
            this.showInvoice = true;
        },
        isLeaseEnded(lease) {
            if (lease.end_date?.length) {
                return true;
            }

            return false;
        },
        leaseSelectionColor(lease) {
            return this.isLeaseEnded(lease) ? '#637381' : '#101318';
        },
        leaseSelectionClass(lease) {
            return this.isLeaseEnded(lease) ? 'hb-text-night-light' : 'hb-text-night';
        }
    },
    props: ['contact', 'showAside', 'contactLeases', 'propertyView'],
    watch: {
        /*
        async contactLeases(){
            if(this.propertyView){
                await this.resetFilters();
                await this.fetchLedger(this.mainView);
            }
        },
        */
        async mainView() {
            this.newLedger = [];
            if (this.propertyView) {
                await this.resetFilters();
                await this.fetchLedger(this.mainView);
                this.hideFutureCharges();
            }
        },
        customStartDate() {
            if (this.customStartDate === '') {
                this.customEndDate = '';
            }
        },
        async propertyView() {
            if (this.mainView.Unit.type !== 'tenant') {
                this.mainView = {
                    id: null,
                    Unit: {
                        number: 'Tenant',
                        type: 'tenant',
                    }
                };
            } else {
                await this.resetFilters();
                await this.fetchLedger(this.mainView);
                this.hideFutureCharges();
            }
        }
    }

}
</script>

<style lang="scss">
#transaction-history {
    table {
        thead {
            tr {
                th {
                    padding-right: 0;
                }
            }
        }

        tbody {
            tr {
                td {
                    overflow-wrap: break-word;
                }
            }

            tr.row-payment {
                background-color: #f0fff1;
                background-image: url('/img/green.png');
                background-repeat: no-repeat;
                background-size: 100% 48px;
            }

            tr.row-invoice {
                background-color: #fcfcfc;
                background-image: url('/img/invoice.png');
                background-repeat: no-repeat;
                background-size: 100% 48px;
            }

            tr.row-future-invoice {
                background-color: #F9FAFB;
                background-image: url('/img/invoice-hover.png');
                background-repeat: no-repeat;
                background-size: 100% 48px;
            }

            tr.row-refund {
                background-color: #FFF9DA;
                // background-image: url('/img/invoice.png');
                background-repeat: no-repeat;
                background-size: 100% 48px;
            }

            tr.row-void {
                background-color: #fcfcfc;
                background-image: url('/img/void.png');
                background-repeat: no-repeat;
                background-size: 100% 48px;
            }

            tr.row-future-void {
                background-color: #F9FAFB;
                background-image: url('/img/void-hover.png');
                background-repeat: no-repeat;
                background-size: 100% 48px;
            }
        }

        tbody {
            tr.row-payment:hover {
                background-color: #f0fff1 !important;
                background-image: url('/img/green-hover.png') !important;
                background-repeat: no-repeat !important;
                background-size: 100% 48px !important;
            }

            tr.row-invoice:hover {
                background-color: #fcfcfc !important;
                background-image: url('/img/invoice-hover.png') !important;
                background-repeat: no-repeat !important;
                background-size: 100% 48px !important;
            }

            tr.row-future-invoice:hover {
                background-color: #F9FAFB !important;
                background-image: url('/img/invoice-hover.png') !important;
                background-repeat: no-repeat !important;
                background-size: 100% 48px !important;
            }

            tr.row-refund:hover {
                background-color: #FFF9DA !important;
                // background-image: url('/img/invoice-hover.png') !important;
                background-repeat: no-repeat !important;
                background-size: 100% 48px !important;
            }

            tr.row-void:hover {
                background-color: #fcfcfc !important;
                background-image: url('/img/void-hover.png') !important;
                background-repeat: no-repeat !important;
                background-size: 100% 48px !important;
            }

            tr.row-future-void:hover {
                background-color: #F9FAFB !important;
                background-image: url('/img/void-hover.png') !important;
                background-repeat: no-repeat !important;
                background-size: 100% 48px !important;
            }
        }
    }
}

.header-td-height {
    height: 32px !important;
}

.furture-charges {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #00848e;
}

.select-filter {
    border: 1px solid #DFE3E8;
    border-radius: 4px;
    width: 175px;
    height: 40px;
    background: white;
}

.select-filter:hover {
    border: 1px solid #C4CDD5;
    cursor: pointer;
}

.select-filter.download-center-filters-active {
    border: 2px solid #00848E;
}

.hb-sub-transaction-row {
    height: 24px;
    padding: 15px 0 13px;
    line-height: 14px;
}

.hb-sub-transaction-row:nth-child(1) {
    height: 24px;
    padding: 24px 0 13px;
    line-height: 14px;
}

.open-margin {
    margin: 13.5px 0;
}

.hb-text-field-custom {
    width: 280px;
}

.custom-flex-gap {
    //flex-direction: column;
    gap: 10px;

    flex-wrap: wrap
}
</style>
